/* eslint-disable react/no-danger */
import Autolinker from 'autolinker'
import classnames from 'classnames'
import DOMPurify from 'dompurify'
import { useEffect } from 'react'
import { useAnalytics } from '../../context/AnalyticsProvider'

export default function Text({ op, children }) {
  const { trackUrl } = useAnalytics()

  const attributes = op?.attributes || {}
  const classes = classnames('whitespace-pre-wrap', {
    [`ml-${op?.attributes?.indent * 10}`]:
      attributes.indent != null && attributes.list == null,
    'font-bold': attributes.bold,
    italic: attributes.italic,
    'line-through': attributes.strike,
    underline: attributes.underline,
  })

  useEffect(() => {
    if (op?.attributes?.link) {
      trackUrl(`${op?.insert?.value?.replaceAll(' ', '-')}-text-tag`)
    }
  }, [op, op?.attributes])

  // if we have the link attribute wrap content in a tag
  const value = op?.attributes?.link
    ? `<a id="${op?.insert?.value}-text-tag" class="text-topic-blue break-all" target=”_blank” href='${op.attributes.link}'>${op.insert.value}</a>`
    : op.insert.value

  return (
    <span className={classes}>
      {children}
      <span
        className="text-offBlack text-sm break-word"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            Autolinker.link(value, {
              className: 'text-topic-blue break-all',
              replaceFn(match) {
                const tag = match.buildTag()

                if (match?.matchedText) {
                  const id = `${op?.insert?.value?.replaceAll(
                    ' ',
                    '-'
                  )}-text-tag`
                  tag.setAttr('id', id)
                }

                trackUrl(`${op?.insert?.value?.replaceAll(' ', '-')}-text-tag`)

                return tag
              },
            }),
            { ADD_ATTR: ['target'] }
          ),
        }}
      />
    </span>
  )
}
