import fetchJson from '../fetchJson'

async function fetchTopics(searchTerm) {
  try {
    const response = await fetchJson(`/api/topics.json?search=${searchTerm}`)
    return response?.topics
  } catch (err) {
    return []
  }
}

export default fetchTopics
