import Image from 'next/image'
import { SRLWrapper } from 'simple-react-lightbox'

const ResourceImage = ({ image, altText }) => (
  <>
    <SRLWrapper>
      <Image
        key={`${altText}-resource-image`}
        src={image}
        alt={altText}
        width="0"
        height="0"
        sizes="100%"
        className="w-full h-auto mb-4"
      />
    </SRLWrapper>
  </>
)

export default ResourceImage
