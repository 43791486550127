import classnames from 'classnames'
import Link from 'next/link'
import LazyLoad from 'react-lazyload'
import ReactPlayer from 'react-player/lazy'
import mediaPlayerLink from '../../lib/mediaPlayerLink'
import useUser from '../../lib/useUser'
import { useAnalytics } from '../context/AnalyticsProvider'

function isSpamWarning(text) {
  return text?.includes("please confirm that you're a human")
}

const Unfurl = ({ link, video, image, altText, title, description }) => {
  const { user } = useUser()
  const { trackData } = useAnalytics()
  let content = null

  const showText = !isSpamWarning(description)

  if (image) {
    content = (
      <LazyLoad
        offset={800}
        height="100%"
        once
        key={image}
        scrollContainer="#communitylab > div"
        placeholder={
          <img
            src="/img-placeholder.png"
            alt="loading..."
            height="100%"
            className="object-cover h-full w-full"
          />
        }
      >
        <img
          src={image}
          alt={altText}
          className="object-cover mx-auto h-full w-full"
        />
      </LazyLoad>
    )
  }

  const videoUrl = video || mediaPlayerLink(link)

  if (videoUrl) {
    content = (
      <div className="relative" style={{ paddingTop: '56.25%' }}>
        <ReactPlayer
          url={videoUrl}
          controls
          width="100%"
          height="100%"
          className="absolute top-0 left-0"
          onStart={() => {
            trackData('video_played', {
              user,
              video: {
                url: videoUrl,
              },
              played_at: new Date().toISOString(),
            })
          }}
          onEnded={() => {
            trackData('video_ended', {
              user,
              video: {
                url: videoUrl,
              },
              ended_at: new Date().toISOString(),
            })
          }}
        />
      </div>
    )
  }

  const wrapperClasses = classnames(
    'w-full sm:max-w-full h-auto justify-center rounded-none md:rounded sm:justify-between items-center my-2',
    { 'max-h-144': !videoUrl, 'h-full': videoUrl }
  )

  const contentClasses = classnames(
    'w-full h-auto rounded-none md:rounded-t-lg overflow-hidden object-cover',
    {
      'max-h-32 sm:max-h-56 md:max-h-64 lg:max-h-72': !videoUrl,
    }
  )

  const titleContent = (
    <>
      {title && showText && (
        <p className="font-bold text-black text-xs md:text-sm line-clamp-2 mt-2">
          {title}
        </p>
      )}
      {showText && (
        <p className="text-disabled text-xs md:text-sm line-clamp-3">
          {description || 'Discover more by visiting the external link'}
        </p>
      )}
    </>
  )

  return (
    <div className={wrapperClasses}>
      {content && <div className={contentClasses}>{content}</div>}
      <div className="w-full flex flex-col justify-center px-2 py-2 bg-gray-100 rounded-none md:rounded-b-lg">
        {!videoUrl ? (
          <Link href={link} target="_blank">
            {titleContent}
          </Link>
        ) : (
          <>{titleContent}</>
        )}
        <LinkDisplay link={link} />
      </div>
    </div>
  )
}

const LinkDisplay = ({ link }) => {
  const { track } = useAnalytics()

  if (!link) {
    return null
  }

  const regex = /^(?:https?:\/\/)?(?:www\.)?/i
  let displayLink = link.replace(regex, '')

  if (displayLink.length > 50) {
    const firstHalf = displayLink.substring(0, 15)
    const secondHalf = displayLink.substring(displayLink.length - 15)
    displayLink = `${firstHalf}...${secondHalf}`
  }

  return (
    <Link
      href={link}
      className="text-disabled text-xs md:text-sm break-all line-clamp-1 lg:max-w-400 mt-2"
      target="_blank"
      onClick={() =>
        track('url_clicked', {
          url: link,
          text: displayLink,
        })
      }
    >
      {displayLink}
    </Link>
  )
}

export default Unfurl
