import * as yup from 'yup'

export default function richContent(name = 'Post text', minLength = 3) {
  return yup
    .object()
    .shape({
      html: yup
        .string()
        .typeError(`${name} is required`)
        .test(
          'stripped-html-length',
          `${name} should be ${minLength} or longer`,
          function (value) {
            const plain = stripHtml(value)
            return plain != null && plain.length >= minLength
          }
        ),
      delta: yup.object().shape({
        ops: yup.array(),
      }),
    })
    .typeError(`${name} is required`)
}

function stripHtml(html) {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}
