import React from 'react'
import BasicErrorMessage from '../common/BasicErrorMessage'
import Button from '../common/Button'
import BaseModal from '../common/modal/BaseModal'
import ResourceRow from '../forms/common/ResourceRow/ResourceRow'

function ChatResource({
  modalVisible,
  onRequestClose,
  register,
  setImage,
  setFile,
  image,
  file,
  reset,
  linkError,
  trigger,
  getValues,
  setValue,
  videoId,
  setVideoId,
  videoEnabled = false,
}) {
  const shouldRenderAddButton =
    (getValues('isFile') && file) ||
    (!getValues('isFile') && !getValues('isLink') && image) ||
    (getValues('isVideo') && videoId !== null)
  return (
    <BaseModal
      title="Add attachment"
      closeIcon
      isOpen={modalVisible}
      onRequestClose={() => {
        reset({
          link: null,
          isImage: false,
          isLink: false,
          isFile: false,
          altText: null,
        })
        setImage(null)
        setFile(null)
        onRequestClose()
      }}
    >
      <div className="max-w-screen px-2">
        <ResourceRow
          register={register}
          setFile={setFile}
          setImage={setImage}
          file={file}
          image={image}
          getValues={getValues}
          setValue={setValue}
          videoId={videoId}
          setVideoId={setVideoId}
          allowVideoUpload={videoEnabled}
        />
      </div>
      {getValues('isLink') && linkError && (
        <BasicErrorMessage error={linkError} />
      )}
      {getValues('isLink') && (
        <Button
          primary
          className="mt-6"
          onClick={async () => {
            const result = await trigger(['link'])
            if (result) {
              await onRequestClose()
            }
          }}
        >
          Add
        </Button>
      )}
      {shouldRenderAddButton && (
        <Button primary onClick={onRequestClose} className="mt-6">
          Add
        </Button>
      )}
    </BaseModal>
  )
}

export default ChatResource
