import Link from 'next/link'
import React from 'react'
import useMemoizedPathFor from '../../../lib/pathFor'

export default function Mention({ op }) {
  const mentionHref = useMemoizedPathFor('user', op?.insert?.value)

  return (
    <Link href={mentionHref} className="text-topic-blue text-sm">
      @{op.insert.value.value}
    </Link>
  )
}
