import fetchJson from '../fetchJson'

async function fetchMentions(searchTerm, groupId) {
  let url = `/api/users.json?search=${searchTerm}&summary=true`

  if(groupId){
    url = `${url}&source_group_id=${groupId}`
  }

  try {
    const response = await fetchJson(url)
    return response?.users
  } catch (err) {
    return []
  }
}

export default fetchMentions
