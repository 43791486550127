import dynamic from 'next/dynamic'
import { useEffect, useMemo, useRef, useState } from 'react'
import colors from '../../config/colors'
import fetchMentions from '../../lib/api/fetchMentions'
import fetchTopics from '../../lib/api/fetchTopics'

const QuillNoSSRWrapper = dynamic(
  async () => {
    const { default: RQ } = await import('react-quill')
    return ({ forwardedRef, ...props }) => (
      <RQ ref={forwardedRef} {...props} tabIndex={0} />
    )
  },
  {
    ssr: false,
    loading: () => <p>Loading ...</p>,
  }
)

let quillReady = false

async function registerMention() {
  if (typeof window === 'undefined') {
    return
  }

  const Quill = (await import('quill')).default
  const QuillMention = (await import('quill-mention')).default
  Quill.register('modules/mention', QuillMention)

  quillReady = true
}

registerMention()

/*
 * Rich text editor
 *
 * delta:
 *   - When false: returns html from `onChange`
 *   - When true: returns {html, delta} from `onChange` and enables mentions and topics completion
 *
 * singleLine:
 *   - When false: normal text area, enter creates newlines
 *   - When true: enter key triggers `onSubmit`
 *
 * onSubmit: only used when singleLine is true
 */
const CLEditor = ({
  value,
  onChange,
  label,
  required,
  hint,
  topicHint,
  mini,
  error,
  placeholder,
  padded,
  paddedLg,
  delta,
  singleLine,
  onSubmit,
  autoFocus,
  groupId,
}) => {
  const quillRef = useRef()
  useEffect(() => {
    if (quillRef.current == null) {
      return
    }

    if (autoFocus) {
      quillRef.current.focus()
    }
  }, [quillRef.current])

  const paddingAmount = paddedLg ? 80 : 30

  const style = {
    backgroundColor: colors['alt-bg'],
    borderRadius: 6,
    minHeight: mini ? 40 : 200,
    width: '100%',
    borderWidth: error ? 1 : null,
    borderColor: error ? colors.alert : null,
    paddingRight: padded ? paddingAmount : null,
    wordBreak: 'break-word',
  }

  const [menuOpen, setMenuOpen] = useState(false)

  const formats = useMemo(() => {
    const fmts = [
      'bold',
      'italic',
      'underline',
      'strike',
      'list',
      'bullet',
      'indent',
      'link',
    ]
    if (delta) {
      fmts.push('mention')
    }
    return fmts
  }, [delta])

  const modules = useMemo(
    () => getModules(delta, singleLine, onSubmit, setMenuOpen, groupId),
    [delta, singleLine, onSubmit, setMenuOpen, groupId]
  )

  if (!quillReady) {
    return null
  }

  if (value == null) {
    // eslint-disable-next-line no-param-reassign
    value = {
      ops: [],
    }
  }

  const handleAddTopic = async (e) => {
    e.preventDefault()
    if (!menuOpen) {
      quillRef.current.getEditor().focus()
      const range = quillRef.current.getEditor().getSelection()
      const position = range ? range.index : range.length
      quillRef.current.getEditor().insertText(position, ' ')
      quillRef.current.getEditor().getModule('mention').openMenu('#')
    }
  }

  return (
    <>
      {label && (
        <p className="font-bold mb-2">
          {label}
          {required && <span className="text-topic-pink ml-2">*</span>}
        </p>
      )}
      <QuillNoSSRWrapper
        modules={modules}
        formats={formats}
        theme="bubble"
        value={value}
        onChange={(description, _changeset, _source, editor) => {
          if (delta) {
            onChange({ html: description, delta: editor.getContents() })
          } else {
            onChange(description)
          }
        }}
        style={style}
        defaultValue={value}
        placeholder={placeholder}
        forwardedRef={quillRef}
        bounds=".quill"
      />
      {hint && <span className="text-subtitle italic ml-4 mt-2">{hint}</span>}
      {topicHint && (
        <div className="flex flex-row text-gray-400">
          <button
            className="text-topic-blue font-bold mr-2"
            onClick={handleAddTopic}
          >
            Add Topic
          </button>
          Help others find your post
        </div>
      )}
    </>
  )
}

export default CLEditor

function getModules(mentionsEnabled, singleLine, onSubmit, setMenuOpen, groupId) {
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['link'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  }

  if (singleLine) {
    modules.keyboard = {
      bindings: {
        shiftEnter: {
          key: 13,
          shiftKey: true,
          handler() {
            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            const userAgent =
              navigator.userAgent || navigator.vendor || window.opera
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
              onSubmit()
              return false
            }
            return true
          },
        },
        enter: {
          key: 13,
          handler() {
            onSubmit()
            return false
          },
        },
      },
    }
  }

  if (mentionsEnabled) {
    modules.mention = {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@', '#'],
      onOpen: () => setMenuOpen(true),
      onClose: () => setMenuOpen(false),
      isolateCharacter: true,
      dataAttributes: [
        'id',
        'value',
        'denotationChar',
        'link',
        'target',
        'disabled',
        'slug',
      ],
      renderItem: (item) => {
        if (item.color) {
          // Topic
          const color = colors[`topic-${item?.color}`] || colors[item?.color]
          return `
          <span class="flex flex-row items-center">
            <svg stroke="${color}" width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 6.66666H16" stroke="current" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1 12.3333H16" stroke="current" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.625 1L4.75 18" stroke="current" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.25 1L10.375 18" stroke="current" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="ml-2 text-topic-${item?.color}">${item?.name}</span>
          </span>
          `
        }

        // Mention
        return `
          <span class="flex flex-row items-center">
            <img class="rounded-full h-8 w-8 object-cover mr-2" src="${
              item?.avatar || '/user-placeholder.png'
            }" alt="mention avatar" />
            <div class="flex flex-col">
              <span>${item?.name}</span>
              <span class="text-xs text-disabled line-clamp-1">
                ${item?.slug}</span>
            </div>
          </span>
        `
      },
      async source(searchTerm, renderList, mentionChar) {
        let values = []

        if (mentionChar === '@') {
          values = await fetchMentions(searchTerm, groupId)
        } else if (mentionChar === '#') {
          values = [{ name: searchTerm, color: 'offBlack' }]
          renderList(values, searchTerm)
          values = await fetchTopics(searchTerm)
          if (values.length === 0 || values[0]?.name !== searchTerm) {
            values = [{ name: searchTerm, color: 'offBlack' }, ...values]
          }
        }

        // quill-mention requires a value attribute
        values = values.map((val) => ({
          ...val,
          id: val?.id || val?.name,
          slug: val?.slug,
          value: val?.name,
        }))

        renderList(values, searchTerm)
      },
    }
  }

  return modules
}
