import { useState } from 'react'
import useSocket from './api/useSocket'

// Connects to websocket and awaits unfurl data if we don't already have it
export default function useUnfurl(post) {
  const [unfurlData, setUnfurlData] = useState(post.unfurl)

  useSocket(
    post?.unfurl?.queued ? `unfurl-post-${post?.id}` : null,
    {
      channel: 'PostChannel',
      id: post?.id,
    },
    {
      received: (msg) => {
        setUnfurlData(msg.unfurl)
      },
    }
  )

  if (unfurlData == null || unfurlData.queued) {
    return null
  }

  return unfurlData
}
