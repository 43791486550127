import Link from 'next/link'
import React from 'react'
import useMemoizedPathFor from '../../../lib/pathFor'

export default function Topic({ op }) {
  const topicHref = useMemoizedPathFor('topic', op?.insert?.value?.value)

  return (
    <Link href={topicHref} className="text-topic-blue text-sm">
      #{op?.insert?.value?.value}
    </Link>
  )
}
