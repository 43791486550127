import classnames from 'classnames'
import {
  BlockGroup,
  InlineGroup,
  ListGroup,
  ListItem,
  QuillDeltaToHtmlConverter,
} from 'quill-delta-to-html'
import { useEffect, useMemo, useRef, useState } from 'react'
import Mention from './Mention'
import Text from './Text'
import Topic from './Topic'

export default function ActivityContent({ item, collapsable = false, isReply = false }) {
  const [showAllContent, setShowAllContent] = useState(!collapsable)
  const activityContentDivRef = useRef(null)

  useEffect(() => {
    const activityContentDivHeight =
      activityContentDivRef?.current?.offsetHeight
    setShowAllContent(activityContentDivHeight < 240)
  }, [])

  useEffect(() => {
    if (!collapsable) {
      setShowAllContent(true)
    }
  }, [collapsable])

  const classes = classnames('relative overflow-hidden transition-[height]', {
    'max-h-64': !showAllContent && !isReply,
    'max-h-24': !showAllContent && isReply,
    'h-full': showAllContent,
  })

  const groupedOps = useMemo(() => {
    const converter = new QuillDeltaToHtmlConverter(
      item?.content_delta?.ops,
      {}
    )
    return converter.getGroupedOps()
  }, [item?.id, item?.updated_at])

  if (!item.content_delta?.ops?.length) {
    return null
  }

  return (
    <div className={classes}>
      <div ref={activityContentDivRef}>
        {groupedOps.map((group, i) => (
          <DeltaGroup
            key={`${item?.type}-${item?.id}-group-${i}`}
            group={group}
          />
        ))}
      </div>
      {!showAllContent && (
        <>
          <div className="absolute bottom-0 left-0 right-0 h-full bg-gradient-to-t from-white to-transparent pointer-events-none" />
          <div
            onClick={() => setShowAllContent(true)}
            onKeyDown={() => setShowAllContent(true)}
            role="button"
            tabIndex={0}
            className="absolute bottom-0 right-0 text-subtitle text-sm"
            style={{ textAlign: 'center' }}
          >
            ...see more
          </div>
        </>
      )}
    </div>
  )
}

function DeltaGroup({ group }) {
  switch (group.constructor) {
    case BlockGroup:
      return <BlockGroupContent group={group} />
    case InlineGroup:
      return <InlineGroupContent group={group} />
    case ListGroup:
      return <ListGroupContent group={group} />
    case ListItem:
      return <ListItemContent item={group} />
    default:
      return null
  }
}

function InlineGroupContent({ group }) {
  return (
    <>
      {group.ops.map((op, i) => (
        <Op key={i} op={op} />
      ))}
    </>
  )
}

function BlockGroupContent({ group }) {
  return (
    <Op op={group.op}>
      {group.ops.map((op, i) => (
        <Op key={i} op={op} />
      ))}
    </Op>
  )
}

function ListGroupContent({ group }) {
  const listType = group.items[0]?.item?.op?.attributes?.list || 'unordered'

  if (listType === 'ordered') {
    return (
      <ol className="ml-10 list-decimal list-outside">
        <ListItems items={group.items} />
      </ol>
    )
  }
  return (
    <ul className="ml-5 list-disc list-inside">
      <ListItems items={group.items} />
    </ul>
  )
}

function ListItems({ items }) {
  return items.map((listItem, i) => (
    <ListItemContent key={i} listItem={listItem} />
  ))
}

function ListItemContent({ listItem }) {
  return (
    <li>
      {listItem.item.ops.map((op, i) => (
        <Op key={i} op={op} />
      ))}
      {listItem.innerList && <ListGroupContent group={listItem.innerList} />}
    </li>
  )
}

function Op({ op, children }) {
  switch (op.insert?.type) {
    case 'text':
      return <Text op={op}>{children}</Text>
    case 'mention':
      // eslint-disable-next-line no-case-declarations
      const char = op.insert?.value?.denotationChar
      if (char === '@') {
        return <Mention op={op} />
      }
      if (char === '#') {
        return <Topic op={op} />
      }
      break
    default:
      return <p>Unknown op {op.insert?.type}</p>
  }
}
