import {
  flip,
  offset,
  safePolygon,
  shift,
  useClick,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'

function ProBadge({ isPro = false }) {
  const [isOpen, setIsOpen] = useState(false)

  const { x, y, strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(5), flip(), shift()],
  })

  const hover = useHover(context, {
    handleClose: safePolygon(),
  })
  const click = useClick(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    click,
  ])

  if (isPro === false) {
    return null
  }

  return (
    <>
      <CheckBadgeIcon
        className="h-5 w-5 cursor-help text-primary"
        ref={refs.setReference}
        {...getReferenceProps()}
      />
      {isOpen && (
        <div
          ref={refs.setFloating}
          {...getFloatingProps()}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          className="bg-gray-400 text-white text-xs rounded-full py-1 px-2 whitespace-nowrap z-50"
        >
          Ecosystem Builder
        </div>
      )}
    </>
  )
}

export default ProBadge
